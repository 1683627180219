<template>
    <v-dialog v-model="visible" max-width="800px" transition="fade-transition">
        <WindowCard title="Importar datos de perfil de empleado" title-icon="style" :actions="true" actions-align="right">
            <v-form id="form" ref="form">
                <v-row>
                    <v-col cols="12">
                        <v-label>Datos como el nombre completo, y el correo electrónico serán copiados al formulario de creación/edición de usuarios.</v-label>
                    </v-col>
                    <v-col cols="12">
                        <v-card outlined flat>
                        <PersonaListItem v-model="persona" />
                        </v-card>
                    </v-col>
                    <v-col cols="12">
                        <v-autocomplete
                            clearable solo outlined flat dense 
                            prepend-inner-icon="account_circle" label="Seleccione un perfil"
                            v-model="persona" :items="empleados"
                            :item-text="(i) => i.first_name + ' ' + i.last_name" 
                            return-object />
                    </v-col>
                </v-row>
            </v-form>
            <template slot="actions">
                <v-btn depressed color="primary" class="subtitle-2" large @click="save">
                    <v-icon left>done</v-icon>
                    Aceptar
                </v-btn>
                <v-btn depressed color="primary" class="subtitle-2" large @click="cancel">
                    <v-icon left>close</v-icon>
                    Cancelar
                </v-btn>
            </template>
        </WindowCard>
        <Message ref="error" />
    </v-dialog>
</template>

<script>
import WindowCard from '@/shared/components/WindowCard'
import PersonaListItem from '@/dash/components/PersonaListItem'
import Message from '@/shared/components/Message'
import { axiosHelper, messages } from '@/helpers'

export default {
    props: ['value'],
    components: {
        WindowCard,
        PersonaListItem,
        Message
    },
    data: () => ({
        persona: null,
        empleados: [],
        visible: false
    }),
    methods: {
        show() {
            this.persona = null;
            this.visible = true;
            axiosHelper.get('personas/?full')
                .then(response => this.empleados = response.data);
        },
        save() {
            if(!this.$refs.form.validate()) {
                this.$refs.error.show(messages.titles["error_generico"], messages.texts["error_check_fields"], "error");
                return;
            }

            if (!this.persona) {
                this.$refs.error.show(messages.titles["error_generico"], 'Debe seleccionar un perfil', "error");
                return;
            }

            this.$emit("onSave", this.persona);
            this.visible = false;
        },
        cancel() {
            this.visible = false;
        }
    }
}
</script>
<template>
    <div>
        <DialogImportEmpleado ref="dialog_import" @onSave="edit_form" />
        <v-form id="perfil_form" ref="perfil_form">
            <v-row>
                <v-col cols="12">
                    <v-btn large color="secondary" dark depressed class="subtitle-2" @click="$refs.dialog_import.show()">
                        <v-icon left>person_add</v-icon>
                        Importar Perfil de Empleado
                    </v-btn>
                </v-col>
                <v-col cols="12">
                    <DashCard title="Perfil" title-icon="account_box">
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-label>Nombres*</v-label>
                                <v-text-field solo outlined flat dense :rules="[rules.required]" v-model="perfilcap.first_name" label="Nombres" hide-details />
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-label>Apellidos*</v-label>
                                <v-text-field solo outlined flat dense :rules="[rules.required]" v-model="perfilcap.last_name" label="Apellidos" hide-details />
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-label>R.U.T*</v-label>
                                <v-text-field solo outlined flat dense :rules="[rules.required]" v-model="perfilcap.rut" label="11.111.111-1" hide-details />
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-label>Correo electrónico</v-label>
                                <v-text-field solo outlined flat dense v-model="perfilcap.email" label="micorreo@empresa.com" hide-details />
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-label>Empresa*</v-label>
                                <Selector :rules="[rules.required]" dense v-model="perfilcap.empresa" :items="empresas" return-object label="Empresa" create-dialog-title="Crear nueva Empresa" create-dialog-placeholder="Nombre de la empresa" item-text="nombre" />
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-label>Gerencia*</v-label>
                                <Selector :rules="[rules.required]" dense v-model="perfilcap.gerencia" :items="gerencias" return-object label="Gerencia" create-dialog-title="Crear nueva Gerencia" create-dialog-placeholder="Nombre de la gerencia" item-text="nombre" />
                            </v-col>
                            <v-col cols="12">
                                <v-label>(*) Campos obligatorios</v-label>
                            </v-col>
                        </v-row>
                    </DashCard>
                </v-col>
                <v-col cols="12" lg="6" v-if="!selfMode">
                    <DashCard title="Credenciales" title-icon="vpn_key">
                        <v-row>
                            <v-col cols="12">
                                <v-label>Nombre de usuario</v-label>
                                <v-text-field flat dense solo outlined v-model="usuario.username" label="Nombre de usuario" prepend-inner-icon="account_circle" :rules="[rules.required, rules.username]" />
                            </v-col>
                            <v-col cols="12" lg="6" v-if="!$route.meta.editMode">
                                <v-label>Contraseña</v-label>
                                <v-text-field flat dense solo outlined type="password" v-model="usuario.pass1" label="Contraseña" prepend-inner-icon="vpn_key" :rules="[rules.required, rules.password]" />
                            </v-col>
                            <v-col cols="12" lg="6" v-if="!$route.meta.editMode">
                                <v-label>Repita su contraseña</v-label>
                                <v-text-field flat dense solo outlined type="password" v-model="usuario.pass2" label="Contraseña" prepend-inner-icon="vpn_key" :rules="[rules.required, rules.password, exact_pass]" />
                            </v-col>
                        </v-row>
                    </DashCard>
                </v-col>
                <v-col cols="12" lg="6" v-if="!selfMode">
                    <DashCard title="Tipo de Acceso" title-icon="settings">
                        <template v-if="grupos.includes('supers')">
                            <v-alert type="info">
                                Esta opción está deshabilitada para este usuario.
                            </v-alert>
                        </template>
                        <v-row v-else>
                            <v-col cols="12">
                                <v-radio-group v-model="cap_grupo" :rules="[rules.required]">
                                    <v-radio hide-details value="cap_evaluador">
                                        <template slot="label">
                                            <div>
                                                <p class="title pa-0 ma-0">Evaluador/a</p>
                                                <p class="subtitle pa-0 ma-0">Podrá responder evaluaciones grupales y administrar sus grupos de acceso asignados.</p>
                                            </div>
                                        </template>
                                    </v-radio>
                                    <v-radio hide-details value="cap_participante">
                                        <template slot="label">
                                            <div>
                                                <p class="title pa-0 ma-0">Participante</p>
                                                <p class="subtitle pa-0 ma-0">Solo podrá responder las evaluaciones de cada capacitación que se le asigne.</p>
                                            </div>
                                        </template>
                                    </v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                    </DashCard>
                </v-col>
                <v-col cols="12">
                    <DashCard :actions="true" actions-align="right">
                        <template v-slot:actions>
                            <v-btn depressed large text color="primary" :to="{ name: 'dashboard_usuarios' }" exact>
                                <v-icon>close</v-icon>
                                <span class="ml-2 subtitle-2">Descartar</span>
                            </v-btn>
                            <v-btn depressed large color="primary" type="submit" @click="uploadUser">
                                <v-icon>done</v-icon>
                                <span class="ml-2 subtitle-2">Guardar</span>
                            </v-btn>
                        </template>
                    </DashCard>
                </v-col>
            </v-row>
        </v-form>
        <Message ref="dialog" />
        <Message ref="success_dialog" @onClose="$router.push({ name: 'dashboard_usuarios' })" />
    </div>
</template>

<script>
import { axiosHelper, messages, valid_username, valid_email, valid_required, valid_password } from '@/helpers';
import DashCard from '@/shared/components/DashCard'
import Message from '@/shared/components/Message'
import CommonDialog from '@/shared/components/CommonDialog'
import Selector from '@/dash/components/Selector'
import DialogImportEmpleado from '@/educap/dialogs/DialogImportEmpleado'

import { mapState } from 'vuex'

export default {
    components: {
        DashCard,
        Message,
        CommonDialog,
        Selector,
        DialogImportEmpleado
    },
    data: () => ({
        persona: null,
        gerencias: [],
        empresas: [],
        usuario: {
            perfilcap: {}
        },
        rules: {
            required: value => valid_required(value),
            username: value => valid_username(value),
            password: value => valid_password(value),
            email: value => valid_email(value),
        }
    }),
    computed: {
        ...mapState('auth', ['user']),
        editMode() {
            return this.$route.meta.editMode || false;
        },
        selfMode() {
            return this.$route.meta.selfMode || false;
        },
        grupos: {
            get() {
                if(!this.usuario.groups) {
                    this.usuario.groups = [];
                }
                return this.usuario.groups;
            },
            set(value) {
                this.usuario.groups = value;
            }
        },
        cap_grupo: {
            get() {
                var cap_grupos = this.grupos.filter(x => x.startsWith('cap_'));
                return cap_grupos.length > 0 ? cap_grupos[0] : null;
            },
            set(value) {
                var cap_grupos = this.grupos.filter(x => x.startsWith('cap_'));
                if(cap_grupos.length < 1) {
                    this.grupos.push(value);
                } else {
                    var index = this.grupos.indexOf(cap_grupos[0]);
                    this.grupos[index] = value;
                }
            }
        },
        perfilcap: {
            get() {
                this.usuario.perfilcap = this.usuario.perfilcap || {};
                return this.usuario.perfilcap;
            },
            set(value) {
                this.usuario.perfilcap = value;
            }
        },
        exact_pass() {
            return (this.usuario.pass1 == this.usuario.pass2) || "Las contraseñas deben coincidir";
        }
    },
    methods: {
        edit_form(empleado) {
            console.log(empleado);
            this.perfilcap.first_name = empleado.first_name;
            this.perfilcap.last_name = empleado.last_name;
            this.perfilcap.email = empleado.email;
            this.$forceUpdate();
        },
        success() {
            this.$refs.success_dialog.show(messages.titles['success_generico'], messages.texts[this.editMode ? 'success_editar_usuario' : 'success_new_usuario'], 'success');
        },
        error(response) {
            this.$refs.dialog.show(messages.titles['error_generico'], messages.texts['error_desconocido'], 'error');
        },
        uploadUser(e) {
            e.preventDefault();  
            let usuario = { ...this.usuario };
            usuario.password = this.usuario.pass1;

            delete usuario.pass1;
            delete usuario.pass2;

            if(!this.$refs.perfil_form.validate()) {
                this.$refs.dialog.show(messages.titles['error_generico'], messages.texts['error_check_fields'], 'error');
                return;
            }

            if(this.editMode) {
                let id = this.selfMode ? this.user.id : this.$route.params.id;

                axiosHelper.put('edu/usuarios/' + id + '/', usuario)
                    .then(() => this.success())
                    .catch(response => this.error(response));
            } else {
                axiosHelper.post('edu/usuarios/', usuario)
                    .then(() => this.success())
                    .catch(response => this.error(response));
            }
        }
    },
    mounted() {
        let text = "Crear usuario";

        if(this.editMode) {
            text = "Editar usuario";

            let id = this.selfMode ? this.user.id : this.$route.params.id;

            axiosHelper.get('edu/usuarios/' + id + '/editor/')
                .then(response => {
                    this.empresas = response.data.empresas;
                    this.gerencias = response.data.gerencias;
                    this.usuario = response.data.usuario;
                });
        } else {
            axiosHelper.get('edu/usuarios/crear/')
                .then(response => {
                    this.empresas = response.data.empresas;
                    this.gerencias = response.data.gerencias;
                });
        }

        this.$parent.$parent.set_title(text, "account_circle")
    }
}
</script>